import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        promo
        pic
      }
      fields {
        name
      }
    }
  }
`;
export const _frontmatter = {
  "date": "2016-12-04",
  "title": "Truly Ergonomic Keyboard Review",
  "description": "keyboard review for people who type a lot",
  "cover": "/img/cover/tek.jpg",
  "pic": "/img/tek.jpg",
  "color": "#f9ce8d"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Truly Ergonomic sent me their latest TEK keyboard model and wanted my take on it.`}</p>
    <p>{`I write code for a living. I type `}<strong parentName="p">{`a lot`}</strong>{`. A few years ago I began to notice an ache, coldness, and slight numbness in my fourth & pinky fingers. I was pretty worried about it, having seen friends go through surgery for similar issues. I complained about the symptoms to my brother one day. He said "dude you just need a better keyboard. Try this one" and handed me his `}<a parentName="p" {...{
        "href": "https://www.trulyergonomic.com/store/index.php"
      }}>{`truly ergonomic keyboard`}</a>{`. He had always had an interest in crazy keyboards and I figured I might as well give it a try. The Truly Ergonomic Keyboard (a.k.a. TEK) took me a couple weeks to get used to. But within a week the symptoms were gone and I ordered my own. Fast forward three years, I use the TEK almost daily. Hands and fingers never felt better.`}</p>
    <p>{`A few weeks ago the makers of the TEK contacted me and asked if I would like to review the new model. I couldn't wait for it to arrive. I've been coding on it solid for over a week now, and want to share my experience with it.`}</p>
    <h2>{`Layout`}</h2>
    <p>{`The first thing you'll notice about this keyboard is the unique layout. I love that the columns are straight and the rows are staggered. Just like your fingers. This makes for less side-to-side motion while you type. In fact the only reason traditional keyboards have staggered columns was to make typewriters less prone to jamming.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.stack.imgur.com/s7k8n.png",
        "alt": "staggered keys"
      }}></img></p>
    <p>{`I find I much prefer the straight columns of the TEK.`}</p>
    <h2>{`Key Placement`}</h2>
    <p>{`The TEK also aims to reduce strain on your weaker fingers (fourth and pinky). This is a smart move. When learning to play the piano, one of the most important things to develop is a sense of good fingering; leveraging your stronger fingers (first, second, & thumb) for most of the work. This enables pianists to enjoy stronger dynamics (soft vs loud) as well as less fatigue while playing. Traditional keyboards assign your poor pinkies to some of the most common keys: `}<inlineCode parentName="p">{`delete`}</inlineCode>{`, `}<inlineCode parentName="p">{`enter/return`}</inlineCode>{`, `}<inlineCode parentName="p">{`dash`}</inlineCode>{` etc. The TEK moves these to the `}<strong parentName="p">{`center`}</strong>{` of the keyboard and gives the job to your pointer finger. The previous model used to place `}<inlineCode parentName="p">{`tab`}</inlineCode>{` in the center as well, and since I'm so used to that I customized my keybindings for it to be the same way.`}</p>
    <h2>{`Mechanical Keys`}</h2>
    <p>{`If you've never typed on a mechanical keyboard, you don't know what you're missing. Keyboards like Apple's and Dell's use a cheaper `}<a parentName="p" {...{
        "href": "https://deskthority.net/wiki/Rubber_dome"
      }}>{`rubber dome`}</a>{`. Mechanical keys contain actual springs and simply have a higher quality feel to them. The new TEK model uses `}<a parentName="p" {...{
        "href": "https://deskthority.net/wiki/Kaihua_PG1511_series"
      }}>{`Kailh`}</a>{` brown switches. A few years ago the only company making these great switches was `}<a parentName="p" {...{
        "href": "https://deskthority.net/wiki/Cherry_MX"
      }}>{`Cherry MX`}</a>{`. But once their patent expired, new switch makes like Kailh and Gateron started to appear. I'm glad for the competition in the mechanical switches space. The Kailh switches are what most `}<a parentName="p" {...{
        "href": "https://www.razerzone.com/"
      }}>{`Razer`}</a>{` gaming keyboards use as well.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/keyswitches.jpg",
        "alt": "mechanical keys"
      }}></img></p>
    <p>{`Brown keys have a noticeable actuation point - you can feel a slight bump when the key is actually sent to the computer. But they don't have that loud audible click you hear from blue switches. I've tried clear switches in other keyboards and found them to be a bit too stiff, so I'm happy that the TEK uses browns. The Kailh browns do have a slightly heavier feel from my Gateron browns on a different keyboard, but that might be because the unit is still so new.`}</p>
    <h2>{`Customizability`}</h2>
    <p>{`The TEK is pretty good when it comes to how custom you can make your keybindings. Especially now that they've released their `}<a parentName="p" {...{
        "href": "https://trulyergonomic.com/store/layout-designer--configurator--reprogrammable--truly-ergonomic-mechanical-keyboard/"
      }}>{`layout design tool`}</a>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/tek-layout.png",
        "alt": "customize"
      }}></img></p>
    <p>{`You can remap any of the keys to any other key. It is somewhat limited compared to other keyboards I've tried. For example you can't set a key to `}<inlineCode parentName="p">{`cmd+s`}</inlineCode>{` or make any other use of modifiers or multiple keys (as far as I know). So I still have to use a third party keybinding tool like `}<a parentName="p" {...{
        "href": "https://pqrs.org/osx/karabiner/"
      }}>{`Karabiner`}</a>{` for OSX or `}<a parentName="p" {...{
        "href": "https://autohotkey.com/"
      }}>{`AutoHotKey`}</a>{` for Windows which can fill in the gaps pretty nicely.`}</p>
    <h2>{`Wish List`}</h2>
    <p>{`While the TEK has a permanent place in my typing life, I do have a few things I'd love to see changed in future versions:`}</p>
    <h3>{`Bluetooth`}</h3>
    <p>{`I have a beautiful bamboo `}<a parentName="p" {...{
        "href": "https://www.nextdesks.com/"
      }}>{`next desk`}</a>{` at my home office. All my wires are neatly tucked away and out of sight. Except my keyboard wires. If Truly Ergonomic ever offers a bluetooth version I'll be so happy.`}</p>
    <h3>{`Blank Key Caps`}</h3>
    <p>{`With the keyboard being so customizable, I've moved a lot of keys around out of their default positions. So many of my key labels are not correct. It doesn't bother me too much because I try not to look at the keyboard while typing. But still. Would like blank keycaps. I might see if this type of plastic can be melted with acetone to make them blank :)`}</p>
    <h2>{`My Layout`}</h2>
    <p>{`Here is `}<a parentName="p" {...{
        "href": "https://www.trulyergonomic.com/store/layout-designer--configurator--reprogrammable--truly-ergonomic-mechanical-keyboard/#KTo7PD0+P0BBQkNERUw5394pNR4fICEiayMkJSYnLS4AABQaCBUXTBwYDBITNDHhBBYHCQorCw0ODzPlAB0bBhkFKhEQNjc4NODiSktOTeMoLFBSUU/m5AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAX2BhAAAAAAAAAAAAAAAAXF1eVlcAAAAAAAAAAABZWltVAAAAAAAAAAAAYgBjVAAAAAAAAAAAWCsAAAAAAACTAQAMAiMBAAwBigEADAIhAQAMAZQBAAwBkgEADAGDAQAMALYBAAwAzQEADAC1AQAMAOIBAAwA6gEADADpAQAMALhJAEYAAAAAAEitR64AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACk6Ozw9Pj9AQUJDREVMOd/eKzUeHyAhImQjJCUmJy4qLzAUGggVF0wcGAwSEzQx4wQWBwkKLQsNDg8z5+EdGwYZBSoREDY3OOXg4kpLTk0sKCxQUlFP5uQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAF9gYQAAAAAAAAAAAAAAAFxdXlZXAAAAAAAAAAAAWVpbVQAAAAAAAAAAAGIAY1QAAAAAAAAAAFgrAAAAAAAAkwEADAIjAQAMAYoBAAwCIQEADAGUAQAMAZIBAAwBgwEADAC2AQAMAM0BAAwAtQEADADiAQAMAOoBAAwA6QEADAC4SQBGAAAAAABIrUeuAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA="
      }}>{`my current TEK layout`}</a>{`. Feel free to use/modify it however you like. Some things won't make sense without my `}<a parentName="p" {...{
        "href": "https://dl.dropboxusercontent.com/u/3098507/blog/private.xml"
      }}>{`Karabiner bindings`}</a>{`. I have some pretty neat things going though:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The Return key is `}<inlineCode parentName="p">{`return`}</inlineCode>{` when I press it, and a super modifier (`}<inlineCode parentName="p">{`cmd+ctrl+option`}</inlineCode>{`) when held down. This gives me my own `}<inlineCode parentName="p">{`dave`}</inlineCode>{` key as I lovingly call it. I use it for all sorts of custom commands. `}<inlineCode parentName="p">{`dave+L`}</inlineCode>{` locks my mac. `}<inlineCode parentName="p">{`dave+S`}</inlineCode>{` saves my editor and refreshes my current Chrome browser. `}<inlineCode parentName="p">{`dave+R`}</inlineCode>{` stops whatever is running in the current iTerm tab, clears the output, and reruns the last command. Perfect for restarting a webpack dev server for example, without ever leaving the keyboard.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The arrow set on the left side of the TEK I've made control tabbing. So hitting the left arrow switches left one tab in any program that has tabs (Chrome, Atom, etc). Love that one.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`I used a few keys on the custom layer to include the most frequently used special characters for coding. `}<inlineCode parentName="p">{`()`}</inlineCode>{`, `}<inlineCode parentName="p">{`{}`}</inlineCode>{`, `}<inlineCode parentName="p">{`[]`}</inlineCode>{`, and `}<inlineCode parentName="p">{`<>`}</inlineCode>{`. I'll probably write a separate post on this because it takes a while to explain and has been extremely useful.`}</p>
      </li>
    </ul>
    <h2>{`Conclusion`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://www.trulyergonomic.com/store/index.php"
      }}>{`TEK`}</a>{` is a fantastic keyboard. It was the keyboard that fixed some scary ergonomic issues and started my crazy keyboard kick. It helped improve my typing speed, and gave me a fun new hobby to customize as part of my coding craft. I'd recommend it in a heart beat.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      